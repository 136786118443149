import { InjectionToken, makeEnvironmentProviders } from '@angular/core';
const ANALYTICS_PROVIDER = new InjectionToken('EVENT_PROVIDER');
function provideFuturaAnalytics(analyticsProvider) {
  return makeEnvironmentProviders([{
    provide: ANALYTICS_PROVIDER,
    useClass: analyticsProvider
  }]);
}

/*
 * Public API Surface of futura-ui
 */

/**
 * Generated bundle index. Do not edit.
 */

export { ANALYTICS_PROVIDER, provideFuturaAnalytics };
